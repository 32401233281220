.verify-background {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: rgb(247, 247, 252);

  .title-verify {
    font-size: 24px;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .title-verify-failed {
    color: red;
  }

  .title-verify-done {
    color: blue;
  }
}
